import { ChevronRightIcon } from '@heroicons/react/24/solid'
import Image from 'next/image'
import Link from 'next/link'
import { useMemo } from 'react'
import { StationSummary } from '../../types/PumpStations'

export interface StationProps {
  station: StationSummary
}

const imagePathSwitch = (pump: any): string => {
  if (pump.isFaulted) {
    return '/pump_faulted_red.png'
  } else if (
    pump.deviceId &&
    pump.online &&
    pump.outFrequency &&
    pump.outFrequency > 0
  ) {
    return '/pump_running_blue.png'
  } else {
    return '/pump_idle_gray.png'
  }
}

const Station = ({ station }: StationProps): JSX.Element => {
  const stationData = useMemo(() => {
    return station
  }, [station])
  return (
    <div className="bg-white border-t border-b border-gray-200 shadow-sm sm:rounded-lg sm:border mt-4 container">
      <div className="flex justify-between items-center p-4 border-b border-gray-200 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {stationData.edgeNodeName}
        </h3>
        {stationData.isGeneratorPowered === true &&
          stationData.pumps &&
          stationData.pumps[0] &&
          stationData.pumps[0].online !== undefined && (
            <div className="flex ml-4 flex-shrink-0 ml-auto">
              <div className="flex">
                <span
                  className={`${
                    stationData.pumps[0].online === false
                      ? 'bg-orange-500 text-white'
                      : 'bg-green-500 text-black'
                  } inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium`}
                >
                  {`${
                    stationData.pumps[0].online === false
                      ? 'Generator Stopped'
                      : 'Generator Running'
                  }`}
                </span>
              </div>
            </div>
          )}
        <div className="flex ml-4 flex-shrink-0">
          <div className="flex text-sm">
            <span
              className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium ${
                stationData.online
                  ? 'bg-green-500 text-black'
                  : 'bg-orange-500 text-white'
              }`}
            >
              {`${stationData.online ? 'Online' : 'Offline'}`}
            </span>
          </div>
        </div>
      </div>
      <Link href={stationData.edgeNodeId}>
        <div className="flex shadow-xl overflow-hidden sm:rounded-md hover:bg-gray-50">
          <ul role="list" className="flex-1 pr-10 divide-y divide-gray-200">
            {stationData.pumps &&
              stationData.pumps.map((pump) => (
                <li key={pump.deviceId}>
                  <div className="px-4 py-4 flex items-center sm:px-6">
                    <div className="flex min-w-full items-center">
                      <div className="flex items-center">
                        {
                          <Image
                            alt="Pump image"
                            height={50}
                            width={50}
                            src={imagePathSwitch(pump)}
                          ></Image>
                        }
                        <p className="pl-4 text-md leading-6 font-medium text-gray-900">
                          {pump.deviceName}
                        </p>
                      </div>
                      <div className="flex-grow ml-4 sm:ml-10">
                        <p className="text-sm font-medium">
                          Pump Speed:{' '}
                          {`${
                            stationData.online &&
                            pump.online &&
                            !pump.isFaulted &&
                            pump.outFrequency !== null &&
                            pump.outFrequency !== undefined
                              ? // pump.outFrequency > 0
                                pump.outFrequency + ' Hz'
                              : 'N/A'
                          }`}
                        </p>
                        <p className="mt-2 text-sm font-medium">
                          Tank Level:{' '}
                          {stationData.online && pump.online
                            ? pump.tankLevel?.toFixed(0) + '%'
                            : 'N/A'}
                        </p>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <div className="flex text-sm">
                          {pump.isFaulted && (
                            <span className="inline-flex items-center px-2.5 py-0.5 mr-2 rounded-md text-sm font-medium bg-red-600 text-white">
                              {/* <ExclamationTriangleIcon className="text-white -ml-0.5 mr-1.5 h-4" /> */}
                              Fault
                            </span>
                          )}
                          <span
                            className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium ${
                              pump.mode === 'Auto' &&
                              pump.online &&
                              stationData.online
                                ? 'bg-green-500 text-black'
                                : 'bg-orange-500 text-white'
                            }`}
                          >
                            {pump.online && stationData.online
                              ? pump.mode
                              : 'Unknown'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
          <div className="flex items-center">
            <ChevronRightIcon
              className="ml-auto h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </Link>
    </div>
  )
}
export default Station
