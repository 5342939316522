import { XMarkIcon } from '@heroicons/react/20/solid'
import Link from 'next/link'
import { useState } from 'react'

const WhatsNewBanner = (): JSX.Element => {
  const [hidden, setHidden] = useState(false)

  return (
    <div
      className={`${
        hidden ? 'hidden' : ''
      }     relative shadow isolate flex items-center gap-x-6 overflow-hidden bg-blue-500 text-white rounded my-1 px-6 py-2.5 sm:px-3.5 sm:before:flex-1`}
    >
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p>
          We&lsquo;ve released text and email alerts. For details please see our{' '}
          <Link
            className="border border-white mx-2 rounded rounded-full py-1 px-2 hover:bg-blue-600"
            href="/changelog"
          >
            Change Log
          </Link>
        </p>
      </div>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          className="-m-3 p-3 "
          onClick={() => setHidden(true)}
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  )
}

export default WhatsNewBanner
